import React from "react";

import { Paragraph1, Label1 } from "baseui/typography";

import Page from "../../components/internal-page";
import { HeadingLevel, Heading } from "baseui/heading";

const ForSale = () => {
  return (
    <Page
      title="Имущество на продажу"
      path="/collateral/for-sale"
      parent="collateral"
    >
      <HeadingLevel>
        <Paragraph1>Реализация имущества должников</Paragraph1>
        <Heading styleLevel={4}>Объект права</Heading>
        <Paragraph1>
          Жилой дом, площадь 486,6 кв.м, количество этажей: 2
        </Paragraph1>
        <Paragraph1>Общая долевая собственность, доля в праве 1/4</Paragraph1>
        <Label1>Адрес (местонахождение) объекта</Label1>
        <Paragraph1>
          Архангельская обл., Вельский район, г. Вельск, ул. 50 лет Октября, д.
          85В
        </Paragraph1>
        <Paragraph1>Цена: договорная</Paragraph1>

        <Heading styleLevel={4}>Объект права</Heading>
        <Paragraph1>
          Нежилое одноэтажное здание общей площадью 111,7 кв.м с земельным
          участком площадью 413 кв.м.
        </Paragraph1>
        <Paragraph1>
          Возможное использование: магазин, кафе, склад, офис, производственное
          помещение
        </Paragraph1>
        <Label1>Адрес (местонахождение) объекта</Label1>
        <Paragraph1>
          Архангельская обл., Холмогорский район, с. Холмогоры, Набережная им.
          Горончаровского, д. 32Б
        </Paragraph1>
        <Paragraph1>Цена: договорная</Paragraph1>

        <Heading styleLevel={4}>Объект права</Heading>
        <Paragraph1>
          Торговое оборудование и мебель для магазина одежды:
        </Paragraph1>
        <Paragraph1>
          <ul>
            <li>Накопитель 1200*700*720мм - 4 ед.</li>
            <li>Подиум 1500*600*150мм - 2 ед.</li>
            <li>Подиум 1950*600*150мм - 1 ед.</li>
            <li>Подиум 1500*450*150мм - 1 ед.</li>
            <li>Подиум 1250*600*150мм - 3 ед.</li>
            <li>Подиум 1280*750*150мм - 1 ед.</li>
            <li>Островной стеллаж DBT017 - 1 ед.</li>
            <li>Подиум 1220*650*16мм - 3 ед.</li>
            <li>Расчетно-кассовый узел 2400*700*1050мм - 1 ед.</li>
            <li>Рама для зеркала 2700*1000мм - 2 ед.</li>
            <li>Подиум 1250*450*150мм - 4 ед.</li>
            <li>Накопитель 600*600*790мм - 6 ед.</li>
            <li>Молдинги - 64 ед.</li>
            <li>Капитель полуколонны 115003 - 32 ед.</li>
            <li>Ствол полуколонны 116010 - 7 ед.</li>
            <li>Светильники – 113 ед.</li>
            <li>Манекены - 10 ед.</li>
            <li>Шкаф купе - 1 ед.</li>
          </ul>
        </Paragraph1>
        <Label1>Адрес (местонахождение) объекта</Label1>
        <Paragraph1>г. Архангельск (на складе)</Paragraph1>
        <Paragraph1>Цена: договорная</Paragraph1>
      </HeadingLevel>
    </Page>
  );
};

export default ForSale;
